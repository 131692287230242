<template>
  <div>
    <CRow>
      <CCol sm="12">
        <StylesTable
          :items="styles"
          hover
          striped
          border
          small
          fixed
          caption="Estilos"
          @refresh="refreshItems"
        />
      </CCol>
    </CRow>
  </div>
</template>

<script>
import StylesTable from '../../components/styles/StylesTable.vue'
import styles from '../../services/styles';
import store from '../../store'

export default {
  name: 'Styles',
  components: { StylesTable },
  data: function () {
		return {
            styles: []
        }
  },
  mounted: async function() {
    this.loading();
    let response = await styles.get();

    if(response.type == "success"){
      this.styles = response.data;
    }

    this.loaded();
  },
  methods: {
    async refreshItems (items){
      this.loading();
      this.styles = await items;
      this.loaded();
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  }
}
</script>
